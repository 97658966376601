import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { GRID_PADDING, GUTTER, Row } from "@/components/grid";
import { Controls } from "@/components/splide";
import { PAGE_LIMIT } from "@/constants/layout";
import type { PropsWithTheme } from "@/theme";

export const StyledSlideContent = styled.div<PropsWithTheme>`
	display: flex;
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	justify-content: flex-end;
	padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-s);
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export const StyledImage = styled.img<PropsWithTheme & { width: number; height: number }>`
	${({ width, height }) => css`
		width: ${width}px;
		height: ${height}px;
	`};
`;

export const StyledControls = styled(Controls)`
	display: none;
	width: 100%;
	max-width: 65vw;
	margin: 0 auto;
`;

export const StyledSplide = styled(Splide)<PropsWithTheme>`
	width: 100%;

	> div {
		overflow: visible;
	}

	&:hover {
		${StyledControls} {
			display: flex;
		}
	}

	.splide__pagination {
		margin-top: var(--spacing-s);
		padding: 0;
	}

	${({ theme: { palette } }) => css`
		.splide__pagination__page {
			width: 8px;
			height: 8px;
			border: none;
			border-radius: 8px;
			background: ${palette.freeze[300]};
			margin: 0 calc(var(--spacing-xxxs) / 2);
			padding: 0;

			&.is-active {
				background: ${palette.freeze[1000]};
			}
		}
	`};
`;

export const StyledSplideSlide = styled(SplideSlide)`
	${({ theme: { mq, tokens } }) => css`
		transform: translateZ(0);
		transition: transform 175ms ease-out;
		overflow: hidden;
		border-radius: ${tokens.borderRadius["3xl"]};

		@media ${mq.l} {
			&:hover {
				transform: scale(1.03);
			}
		}
	`};
`;

export const StyledHoverLink = styled.a`
	display: block;

	&::after {
		content: "";
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 1.33%, rgba(0, 0, 0, 0));
		background-repeat: repeat-x;
		background-position: 50% 100%;
		background-size: 100% 250px;
	}
`;

const gridAreas = ["A", "B", "C", "D", "E", "F"];

export const CollageSlots = styled.ul<PropsWithTheme<HTMLUListElement> & { slotCount: number }>`
	width: 100%;
	max-width: ${PAGE_LIMIT}px;
	display: grid;
	margin: 0 auto;
	padding: 0 0 8px 0;
	list-style: none;
	${({ theme: { mq }, slotCount }) => {
		switch (slotCount) {
			case 1:
				return css`
					height: 375px;
					grid-template-areas: "${gridAreas[0]}";
					grid-template-columns: 1fr;
					grid-template-rows: 100%;
					@media ${mq.l} {
						height: 600px;
					}
				`;
			case 2:
				return css`
					height: 600px;
					grid-template-areas: "${gridAreas[0]}" "${gridAreas[1]}";
					grid-template-columns: 1fr;
					grid-template-rows: 50%; 50%;
					@media ${mq.l} {
					grid-template-areas: "${gridAreas[0]} ${gridAreas[1]}";
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 100%;
				height: 425px;
					}
				`;
			case 3:
				return css`
					grid-template-areas: "${gridAreas[0]} ${gridAreas[0]}" "${gridAreas[1]} ${gridAreas[2]}";
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 375px 280px;
					@media ${mq.l} {
						height: 850px;
						grid-template-areas: "${gridAreas[0]} ${gridAreas[1]}" "${gridAreas[0]} ${gridAreas[2]}";
						grid-template-rows: 50% 50%;
					}
				`;
			case 4:
				return css`
					grid-template-areas: "${gridAreas[0]} ${gridAreas[1]}" "${gridAreas[2]} ${gridAreas[3]}";
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 280px 280px;
					@media ${mq.l} {
						height: 850px;
						grid-template-rows: 50% 50%;
					}
				`;
			case 5:
			default:
				return css`
					grid-template-areas: "${gridAreas[0]} ${gridAreas[0]}" "${gridAreas[1]} ${gridAreas[2]}" "${gridAreas[3]} ${gridAreas[4]}";
					grid-template-columns: 50% 50%;
					grid-template-rows: 375px 280px 280px;
					@media ${mq.l} {
						height: 1275px;
						grid-template-areas: "${gridAreas[0]} ${gridAreas[1]}" "${gridAreas[0]} ${gridAreas[2]}" "${gridAreas[3]} ${gridAreas[4]}";
						grid-template-columns: 1fr 1fr;
						grid-template-rows: ${100 / 3}% ${100 / 3}% ${100 / 3}%;
					}
				`;
		}
	}};
`;

const getBorderRadius = (radius: string, slotCount: number, index: number) => {
	const values = {
		1: [radius],
		2: [`${radius} 0 0 ${radius} `, `0 ${radius}  ${radius} 0`],
		3: [`${radius} 0 0 ${radius} `, `0 ${radius}  0 0`, `0 0 ${radius}  0`],
		4: [`${radius}  0 0 0`, `0 ${radius}  0 0`, `0 0 0 ${radius} `, `0 0 ${radius}  0`],
		5: [`${radius}  0 0 0`, `0 ${radius}  0 0`, "0", `0 0 0 ${radius} `, `0 0 ${radius}  0`],
	};

	return values?.[slotCount]?.[index] ?? "0";
};

export const CollageSlot = styled.li<{ index: number; slotCount: number }>`
	display: flex;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	transform: translateZ(0);
	transition: transform 175ms ease-out, border-radius 175ms ease-out;
	overflow: hidden;
	${({ theme: { mq, tokens }, index, slotCount }) => css`
		grid-area: ${gridAreas[index]};
		@media ${mq.l} {
			border-radius: ${slotCount && slotCount > 0
				? getBorderRadius(tokens.borderRadius["3xl"], slotCount, index)
				: "none"};
			&:hover {
				border-radius: ${tokens.borderRadius["3xl"]};
				z-index: ${index + 1};
				transform: scale(1.03);
			}
		}
	`};
`;

export const Overlay = styled.a<PropsWithTheme<HTMLAnchorElement>>`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0 0 var(--spacing-xxxs);
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 1.33%, rgba(0, 0, 0, 0));
	background-repeat: repeat-x;
	background-position: 50% 100%;
	background-size: 100% 230px;
	color: white;
	text-decoration: none;
`;

export const Inner = styled.div`
	display: flex;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex: 1;
`;

export const InnerWidth = styled.div<PropsWithTheme & { left?: boolean }>`
	display: flex;
	flex-direction: column-reverse;
	padding: 0 24px;
	${({ theme: { mq, breakpoints }, left }) => css`
		@media ${mq.l} {
			padding-right: 116px;
			align-self: ${left ? "flex-end" : "flex-start"};
			padding-left: 40px;
			width: calc(
				100% - (var(${GRID_PADDING}) + var(${GUTTER})) * 1px - (100vw - ${breakpoints.xl}px) /
					2
			);
		}
		@media (min-width: ${PAGE_LIMIT}px) {
			width: calc(
				100% - (var(${GRID_PADDING}) + var(${GUTTER})) * 1px -
					(${PAGE_LIMIT - breakpoints.xl}px) / 2
			);
		}
	`};
`;

export const PropertyCardsRow = styled(Row)`
	// Bottom padding allowing for box-shadow on cards
	padding-bottom: 10px;
	row-gap: calc(var(--grid-gutter) * 2px);
`;
