import React from "react";

import { PersonEntryList } from "@/components/person-list";
import type { Locale } from "@/contentful/content-model/types";
import type { Person, PersonList as PersonListProps } from "@/types/contentful-api";

export const PersonListSlot: React.VFC<PersonListProps & { locale: Locale }> = props => {
	const {
		headline,
		itemsCollection: { items },
		locale,
	} = props;

	// Filtering out null values from Draft Contentful entries
	const people = items.filter(Boolean);

	return <PersonEntryList locale={locale} headline={headline} data={people as Person[]} />;
};
