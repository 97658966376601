import React from "react";

import { Column } from "@/components/grid";
import { PropertyCard } from "@/components/property-card";
import type { Property } from "@/types/contentful-api";

import { PropertyCardsRow } from "./styled";

interface PropertyListCardsProps {
	properties: Property[];
}

export const PropertyListCards = ({ properties }: PropertyListCardsProps) => {
	const filteredProperties = properties.filter(
		item => Boolean(item?.exportedPropertyData) && Boolean(item?.featuredImage)
	);

	return (
		<PropertyCardsRow>
			{filteredProperties.map(property => (
				<Column key={property.sys.id} m={4} l={4}>
					<PropertyCard shouldTrack property={property} />
				</Column>
			))}
		</PropertyCardsRow>
	);
};
